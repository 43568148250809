
import { dataType } from "./stateType";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { Router, useRoute, useRouter } from "vue-router";
import { dateFormat, QI_LIU_YUN_APPID } from "@/utils/util";
import { uploadImg, uploadVideo } from "../../../../api/user";
import { saveFarmingRecord } from "../../../../api/farmer";
import { Toast } from "vant";
const states: dataType = {
  farmingTypeId: "",
  farmingTypeName: "",
  farmingName: "",
  date: "",
  area: "",
  person: "",
  imageList: [],
  videoList: [],
  other: "",
  isUpload: false,
  id: "",
  showDateDialog: false,
  currentDate: new Date(
    dateFormat().YYYY,
    Number(dateFormat().MM) - 1,
    Number(dateFormat().DD)
  ),
  maxDate: new Date(
    dateFormat().YYYY,
    Number(dateFormat().MM) - 1,
    Number(dateFormat().DD)
  ),
  minDate: new Date(1900, 0, 1),
  uploadSuccessImgList: [],
  uploadSuccessVideo: "",
  showVideos: false,
};

export default defineComponent({
  setup() {
    const state = reactive<dataType>(states);
    const $route = useRoute();
    const $router = useRouter();
    const {
      onSubmit,
      dateConfirm,
      imageUploadAfter,
      afterUploadVideo,
      delBefore,
      delVideoBefore,
    } = useEvent(state, $router);
    onMounted(() => {
      state.farmingTypeId = $route.query.typeId as string;
      state.farmingTypeName = $route.query.typeName as string;
      state.farmingName = $route.query.name as string;
      state.id = $route.query.id as string;
      state.date =
        dateFormat().YYYY + "-" + dateFormat().MM + "-" + dateFormat().DD;
    });
    return {
      ...toRefs(state),
      onSubmit,
      dateConfirm,
      delVideoBefore,
      delBefore,
      imageUploadAfter,
      afterUploadVideo,
    };
  },
});

const useNetwork = (state: dataType, router?: Router) => {
  const saveFarmingInfo = async (data: any) => {
    const result: any = await saveFarmingRecord(data);
    if (result.data.code == 0) {
      Toast("已保存");
      const timer = setTimeout(() => {
        router && router.back();
        const { clear } = useEvent(state);
        clear();
        clearTimeout(timer);
      });
    }
  };

  return {
    saveFarmingInfo,
  };
};

const useEvent = (state: dataType, router?: Router) => {
  // 清除数据
  const clear = () => {
    state.farmingTypeId = "";
    state.farmingTypeName = "";
    state.farmingName = "";
    state.date = "";
    state.area = "";
    state.person = "";
    state.imageList = [];
    state.videoList = [];
    state.other = "";
    state.id = "";
    state.isUpload = false
    state.showDateDialog = false;
    (state.currentDate = new Date(
      dateFormat().YYYY,
      Number(dateFormat().MM) - 1,
      Number(dateFormat().DD)
    )),
      (state.maxDate = new Date(
        dateFormat().YYYY,
        Number(dateFormat().MM) - 1,
        Number(dateFormat().DD)
      ));
    state.minDate = new Date(1900, 0, 1);
    state.uploadSuccessImgList = [];
    state.uploadSuccessVideo = "";
  };
  const delBefore = (e: any, e1: any) => {
    const index = e1.index;
    state.imageList.splice(index, 1);
    state.uploadSuccessImgList.splice(index, 1);
  };
  const afterUploadVideo = (e: any) => {
    e.status = "uploading";
    e.message = "上传中...";
    state.isUpload = true;
    const form = new FormData();
    form.append("appid", QI_LIU_YUN_APPID);
    form.append("uploadFile ", e.file);
    uploadVideo(form)
      .then((res: any) => {
        if (res.data.code == 0) {
          state.uploadSuccessVideo = res.data.data;
          state.isUpload = false;
        } else {
          e.status = "failed";
          e.message = "上传失败";
          state.isUpload = false;
        }
      })
      .catch(() => {
        state.videoList = [];
        e.status = "failed";
        e.message = "上传失败";
        state.isUpload = false;
      });
  };
  const delVideoBefore = () => {
    state.uploadSuccessVideo = "";
    state.videoList = [];
  };

  const imageUploadAfter = (e: any) => {
    // 数组就是有多张 否则就只有一张
    const form = new FormData();
    form.append("appid", QI_LIU_YUN_APPID);
    if (Array.isArray(e)) {
      e.forEach((item) => {
        form.append("uploadFile", item.file);
        uploadImg(form)
          .then((res: any) => {
            if (res.data.code == 0) {
              state.uploadSuccessImgList.push(res.data.data);
            }
          })
          .catch(() => Toast("上传失败"));
      });
    } else {
      form.append("uploadFile", e.file);
      uploadImg(form)
        .then((res: any) => {
          if (res.data.code == 0) {
            state.uploadSuccessImgList.push(res.data.data);
          }
        })
        .catch((err) => Toast("上传失败"));
    }
  };
  const dateConfirm = (e: any) => {
    state.date =
      dateFormat(e).YYYY + "-" + dateFormat(e).MM + "-" + dateFormat(e).DD;
    state.showDateDialog = false;
  };
  const onSubmit = async (values: any) => {
    const { saveFarmingInfo } = useNetwork(state, router);
    const param = {
      area: state.area,
      farmingName: state.farmingName,
      farmingType: state.farmingTypeId,
      remark: state.other,
      video: state.uploadSuccessVideo,
      img: state.uploadSuccessImgList.join(","),
      artificial: state.person,
      workTime: state.date,
      optionId: state.id,
    };
    saveFarmingInfo(param);
  };
  return {
    onSubmit,
    dateConfirm,
    delVideoBefore,
    imageUploadAfter,
    afterUploadVideo,
    delBefore,
    clear,
  };
};
